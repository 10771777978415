<template>
  <div>
    <!-- Start Breadcrump Area  -->
    <div
      class="rn-page-title-area ptb--60 bg_image bg_image--27"
      data-black-overlay="7"
    >
      <v-container>
        <v-row>
          <v-col cols="12">
            <div class="text-center blog-single-page-title pt--100">
              <h1 class="heading-title theme-gradient">
                Mise en route sur Amazon Alexa
              </h1>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!-- End Breadcrump Area  -->

    <!-- Start Blog Details Area  -->
    <div class="rn-blog-details pt--60 pb--60 bg_color--1">
      <v-container>
        <v-row>
          <v-col cols="12">
            <div class="inner-wrapper">
              <div class="inner">
                <div class="flex-wrap blog-single-list-wrapper d-flex">
                  <div class="thumbnail">
                    <img
                      class="w-100"
                      src="../../assets/images/pricing/assistance_login_alexa.jpg"
                      alt="google assistant"
                    />
                  </div>
                  <div class="content">
                    <h4 class="heading-title">
                      Pour utiliser le service sur Amazon Alexa vous devez:
                    </h4>
                    <ul class="list-style">
                      <li>
                        Activer la skill sur Alexa sur
                        <a
                          href="https://alexa-skills.amazon.fr/apis/custom/skills/amzn1.ask.skill.143b926e-dc46-4c97-a979-026b6f3004ba/launch"
                          target="_blank"
                          >le portail Alexa</a
                        >
                      </li>
                      <li>
                        Connectez Mes amis de confiance avec votre compte Alexa
                        (le même compte qui a servi à paramétrer votre
                        assistant)
                      </li>
                      <li>
                        Saisir les coordonnées ( nom , prénom téléphone fixe ou
                        portable , e-mail) une liste d’amis , proches , voisins
                        de confiance susceptibles de répondre à une demande
                        d’assistance . Nous notifierons par mail ou sms tous vos
                        contacts qui font partie de votre réseau de confiance.
                      </li>
                    </ul>
                    <h4 class="heading-title">
                      Voilà c'est terminé !
                    </h4>
                    <p>
                      À partir de maintenant vous pouvez uliliser le service
                      quand vous voulez il suffit de dire:
                      <br />
                      <em>Alexa lance mes amis de confiance</em>
                    </p>
                    <p>
                      Vous pouvez aussi configurer
                      <a href="https://youtu.be/lU4PIGFXRbQ" target="_blank"
                        >une routine</a
                      >
                      afin de pouvoir dire
                      <br />
                      <em>Alexa je suis tombé</em>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!-- End Blog Details Area  -->

    <Footer />
  </div>
</template>

<script>
export default {
  data() {
    return {
      logo: require("../../assets/images/logo/logo.png"),
      logoLight: require("../../assets/images/logo/logo-light.png"),
      logoDark: require("../../assets/images/logo/logo-dark.png"),
      formData: {
        name: "",
        email: "",
        subject: "",
        message: ""
      },
      items: [
        {
          thumb: require("../../assets/images/blog/bl-big-01.jpg"),
          src: "https://www.youtube.com/watch?v=ZOoVOfieAF8"
        }
      ],
      metaList: [
        {
          icon: "clock",
          text: "May 18, 2020"
        },
        {
          icon: "user",
          text: "Fatima"
        },
        {
          icon: "message-circle",
          text: "15 Comments"
        },
        {
          icon: "heart",
          text: "Like"
        }
      ],
      index: null
    };
  },

  methods: {
    onSubmit() {}
  }
};
</script>
